// src/LandingPage.js
import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

const categories = [
  {
    id: 1,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA01",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 01",
  },
  {
    id: 2,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA02",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 02",
  },
  {
    id: 3,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA03",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 03",
  },
  {
    id: 4,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA04",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 04",
  },
  {
    id: 5,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA05",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 05",
  },
  {
    id: 6,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA06",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 06",
  },
  {
    id: 7,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA07",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 07",
  },
  {
    id: 8,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA08",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 08",
  },
  {
    id: 9,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA09",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 09",
  },
  {
    id: 10,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA10",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 10",
  },
  {
    id: 11,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN01",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 11",
  },
  {
    id: 12,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN02",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 12",
  },
  {
    id: 13,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN03",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 13",
  },
  {
    id: 14,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN04",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 14",
  },
  {
    id: 15,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN05",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 15",
  },
  {
    id: 16,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA11",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 16",
  },
  {
    id: 17,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA12",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 17",
  },
  {
    id: 18,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA13",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 18",
  },
  {
    id: 19,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA14",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 19",
  },
  {
    id: 20,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA15",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 20",
  },
  {
    id: 21,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA16",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 21",
  },
  {
    id: 22,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA17",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 22",
  },
  {
    id: 23,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA18",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 23",
  },
  {
    id: 24,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA19",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 24",
  },
  {
    id: 25,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA20",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 25",
  },
  {
    id: 26,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN06",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 26",
  },
  {
    id: 27,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN07",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 27",
  },
  {
    id: 28,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN08",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 28",
  },
  {
    id: 29,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN09",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 29",
  },
  {
    id: 30,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN10",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 30",
  },
  {
    id: 31,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA21",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 31",
  },
  {
    id: 32,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA22",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 32",
  },
  {
    id: 33,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA23",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 33",
  },
  {
    id: 34,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA24",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 34",
  },
  {
    id: 35,
    name: "normal",
    image: "./model04.jpg",
    author: "PRA25",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 35",
  },
  {
    id: 36,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN11",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 36",
  },
  {
    id: 37,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN12",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 37",
  },
  {
    id: 38,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN13",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 38",
  },
  {
    id: 39,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN14",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 39",
  },
  {
    id: 40,
    name: "normal",
    image: "./model04.jpg",
    author: "SAN15",
    head: "පෙරහුරු ප්‍රශ්න පත්‍රය",
    sub: "අංක 40",
  },
];

const LandingPage = () => {
  const navigate = useNavigate();

  const handleCategoryClick = (category, author) => {
    navigate("/quiz", { state: { category, author } });
  };

  return (
    <>
      <Navbar />
      <div className="min-h-screen flex flex-wrap justify-center items-center bg-gray-100 p-4">
        {categories.map((cat) => (
          <div
            key={cat.id}
            onClick={() => handleCategoryClick(cat.name, cat.author)}
            className="w-1/4 p-4 cursor-pointer"
          >
            <div className="bg-white shadow-md hover:shadow-lg rounded-lg p-4 transition-all">
              <img
                src={`assets/${cat.image}`}
                alt={cat.name}
                className="w-full h-40 object-cover rounded-lg mb-4"
              />
              <h2 className="text-lg font-semibold text-center">{cat.head}</h2>
              <p className="text-sm text-center text-gray-500">{cat.sub}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default LandingPage;
