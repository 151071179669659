import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function PastpaperSelector() {
  const navigate = useNavigate();

  const [subject, setSubject] = useState("maths");
  const [year, setYear] = useState("2022");

  const subjects = [
    { label: "ගණිතය", value: "maths" },
    { label: "විද්‍යාව", value: "science" },
    { label: "English", value: "english" },
    { label: "ඉතිහාසය", value: "history" },
    { label: "භූගෝල විද්‍යාව", value: "geography" },
    { label: "සෞඛ්‍ය", value: "helth" },
    { label: "බුද්ධ ධර්මය", value: "buddhist" },
    { label: "තොරතුරු හා සන්නිවේදන තාක්ෂණය", value: "ict" },
  ];

  const years = [
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const subjectYear = subject + year; // e.g. 'maths2022'
    // navigate(`/pastpapers/${subjectYear}`);
    navigate("/quiz", { state: { fileName: `${subjectYear}` } });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-md bg-white rounded-xl shadow-md p-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">
          Select Past Paper
        </h1>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col">
            <label
              htmlFor="subjectSelect"
              className="font-semibold text-gray-700 mb-1"
            >
              Subject:
            </label>
            <select
              id="subjectSelect"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {subjects.map((subj) => (
                <option key={subj.value} value={subj.value}>
                  {subj.label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="yearSelect"
              className="font-semibold text-gray-700 mb-1"
            >
              Year:
            </label>
            <select
              id="yearSelect"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {years.map((yr) => (
                <option key={yr} value={yr}>
                  {yr}
                </option>
              ))}
            </select>
          </div>

          <button
            type="submit"
            className="mt-4 py-2 px-4 rounded-full text-white font-semibold bg-gradient-to-r from-indigo-500 to-indigo-600 hover:to-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none transform hover:scale-105 transition-transform"
          >
            Load Past Paper
          </button>
        </form>
      </div>
    </div>
  );
}

export default PastpaperSelector;
