import React from "react";
import "./App.css";

import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Quiz from "./Quiz";

import LandingPage from "./LandingPage";
import Result from "./Result";
import Home from "./Home";

import PastpaperSelector from "./components/PastpaperSelector";
// import SubjectYearPage from "./components/SubjectYearPage";
// import ExamPage from "./components/ExamPage"; // Assume this is already implemented

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/LandingPage" element={<LandingPage />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/result" element={<Result />} />
        <Route path="/pastpapers" element={<PastpaperSelector />} />
        {/* <Route path="/pastpapers/:subjectYear" element={<SubjectYearPage />} /> */}
        {/* <Route path="/exam" element={<ExamPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
