import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Result = () => {
  const location = useLocation();
  const { questions = [], answers = [], score = 0 } = location.state || {};
  const navigate = useNavigate();

  if (questions.length !== answers.length) {
    return <div>Mismatch between the number of questions and answers!</div>;
  }

  const handleRetakeQuiz = () => {
    navigate("/");
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="max-w-md w-full bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Quiz Completed</h2>
        <p className="text-lg mb-4">
          Your Score: {score} / {questions.length}
        </p>

        {/* Show question review */}
        <div className="space-y-6">
          {questions.map((question, index) => {
            const isCorrect = answers[index] === question.correctOption;
            const resultClass = isCorrect ? "bg-green-500" : "bg-red-500";
            const correctAnswer = question[`option${question.correctOption}`];

            return (
              <div key={index} className="mb-4">
                {/* Question */}
                <p className="font-semibold">{question.question}</p>

                {/* Show image if available */}
                {question.img && (
                  <img
                    src={question.img}
                    alt="Question"
                    className="w-full h-40 object-cover mb-4"
                  />
                )}

                {/* Answer review */}
                <div>
                  <p className={`${resultClass} py-2 px-4 rounded-md`}>
                    Your Answer: {question[`option${answers[index]}`]}
                  </p>
                  <p className="text-green-500 mt-2">
                    Correct Answer: {correctAnswer}
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-6 flex justify-between">
          <button
            onClick={handleRetakeQuiz}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg"
          >
            Retake the Exam
          </button>
        </div>
      </div>
    </div>
  );
};

export default Result;
