import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  return (
    <nav style={styles.navbar}>
      <h1 style={styles.title}>පෙරහුරු ප්‍රශ්න</h1>
      <button style={styles.button} onClick={goToHome}>
        Back to Home
      </button>
    </nav>
  );
};

const styles = {
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 30px",
    background: "linear-gradient(90deg, #6a11cb, #2575fc)",
    color: "white",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: "bold",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
    fontFamily: "'Noto Sans Sinhala', sans-serif",
    margin: 0,
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    backgroundColor: "#ff6f61",
    color: "white",
    border: "none",
    borderRadius: "30px",
    cursor: "pointer",
    transition: "all 0.3s ease",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.2)",
  },
  buttonHover: {
    backgroundColor: "#e35d52",
    transform: "scale(1.05)",
  },
};

export default Navbar;
